import React from 'react'
import About from '../features/About'
import Footer from '../features/Footer'

function Home() {
  return (
    <div className="w-screen min-h-screen flex flex-col">
      <About />
      <div className="container pt-5 flex-grow">
        {/* Events */}
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
        <div
          id="events"
          class="elfsight-app-4c9551ae-13dd-45a9-b64e-bf23eac8c051 mt-28"></div>
        {/* Gallery */}
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
        <div
          id="gallery"
          class="elfsight-app-c66c52ea-7075-4b03-ba3f-2b7b93fbc0ca mt-20"></div>
        {/* Contact us */}
        <div id="contact-us" className="w-full md:w-1/2 mt-20">
          <div class="elfsight-app-b99641f5-1659-4a94-b808-ab0599112ffa"></div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home

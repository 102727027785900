import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer className="bg-gradient-to-br from-secondary-yellow to-secondary-green py-10">
      <div className="container flex justify-center items-center text-center">
        {/* <Link href="/privacy">
            <a>Privacy Policy</a>
          </Link> */}
        © 2022 AFSA | All Rights Reserved | Powered by aKumoSolutions
      </div>
    </footer>
  )
}

export default Footer

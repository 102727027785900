import React from 'react'
import Logo from '../../assets/logo.svg'
import { FacebookLogo, InstagramLogo } from '../../icons'
import { MenuToggle } from './MenuToggle'
import { useRef } from 'react'
import { motion, useCycle } from 'framer-motion'
import { MenuItem } from './MenuItem'
import { useDimensions } from './use-dimensions'

export const links = [
  {
    title: 'Home',
    link: '/#',
  },
  {
    title: 'Events',
    link: '/#events',
  },
  {
    title: 'Gallery',
    link: '/#gallery',
  },
  {
    title: 'Contact us',
    link: '/#contact-us',
  },
  {
    title: 'Members',
    link: '/members',
  },
]

export const contacts = [
  {
    Icon: FacebookLogo,
    link: 'https://www.facebook.com/AKumoSolutions-100763001568331/',
  },
  {
    Icon: InstagramLogo,
    link: 'https://www.facebook.com/AKumoSolutions-100763001568331/',
  },
]

const variants = {
  open: {
    opacity: 1,
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    opacity: 0,
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

export const Navigation = () => {
  return (
    <motion.ul
      className="fixed top-0 left-0 bg-gradient-to-br from-secondary-yellow to-secondary-green h-screen w-screen z-30 flex flex-col"
      variants={variants}
      initial="closed"
      animate="open">
      <div className="mt-32 px-5 grid gap-5">
        {links.map(({ title, link }) => (
          <MenuItem href={link} key={link}>
            {title}
          </MenuItem>
        ))}
      </div>
    </motion.ul>
  )
}

export const Sidebar = () => {
  const [isOpen, toggleOpen] = useCycle(false, true)
  const containerRef = useRef(null)
  const { height } = useDimensions(containerRef)

  return (
    <motion.nav
      className="flex"
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      custom={height}
      ref={containerRef}>
      {isOpen && (
        <div onClick={toggleOpen}>
          <Navigation />
        </div>
      )}

      <MenuToggle toggle={toggleOpen} />
    </motion.nav>
  )
}

function Nav() {
  return (
    <nav className="w-full py-8 flex justify-between items-center">
      <div className="flex items-center">
        <img src={Logo} alt="AFSA" />
        <div className="hidden sm:flex ml-10">
          {links.map(({ title, link }, i) => (
            <a
              key={i}
              href={link}
              className="py-2 px-3 hover:text-primary-violet transition-colors duration-150">
              {title}
            </a>
          ))}
        </div>
      </div>
      <div className="flex">
        {contacts.map(({ Icon, link }, i) => (
          <a
            href={link}
            key={i}
            target="_blank"
            rel="noreferrer"
            className="leading-xl px-2">
            <Icon className="w-5 text-black hover:text-primary-blue stroke-current" />
          </a>
        ))}
      </div>
      <div className="sm:hidden">
        <Sidebar />
      </div>
    </nav>
  )
}

export default Nav

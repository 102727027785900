import React from 'react'
import Nav from '../features/Nav'
import Footer from '../features/Footer'
import Member from '../assets/member.png'

function Members() {
  return (
    <div className="min-h-screen flex flex-col">
      <div className="w-full bg-gradient-to-br from-secondary-yellow to-secondary-green pb-10">
        <div className="container">
          <Nav />
          <h1 className="font-primary text-2xl md:text-3xl leading-[135%] uppercase tracking-[0.1em]">
            Members
          </h1>
        </div>
      </div>
      <div className="container flex-grow py-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          <div className="member-card flex flex-col md:flex-row justify-between p-5 col-span-1 rounded">
            <img
              src={Member}
              alt=""
              className="rounded w-[170px] h-[220px] mx-auto"
            />
            <div className="px-5 mt-5">
              <h4 className="font-merriweather leading-[135%] tracking-[0.1em]">
                Melisa Garden
              </h4>
              <span className="text-[#767070] font-bold text-xs mt-1">
                Frontend Engineer
              </span>
              <p className="mt-5">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Pariatur reiciendis tenetur similique maxime! Laboriosam dolore
                deleniti eius cum deserunt porro.
              </p>
            </div>
          </div>
          <div className="member-card flex flex-col md:flex-row justify-between p-5 col-span-1 rounded">
            <img
              src={Member}
              alt=""
              className="rounded w-[170px] h-[220px] mx-auto"
            />
            <div className="px-5 mt-5">
              <h4 className="font-merriweather leading-[135%] tracking-[0.1em]">
                Melisa Garden
              </h4>
              <span className="text-[#767070] font-bold text-xs mt-1">
                Frontend Engineer
              </span>
              <p className="mt-5">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Pariatur reiciendis tenetur similique maxime! Laboriosam dolore
                deleniti eius cum deserunt porro.
              </p>
            </div>
          </div>
          <div className="member-card flex flex-col md:flex-row justify-between p-5 col-span-1 rounded">
            <img
              src={Member}
              alt=""
              className="rounded w-[170px] h-[220px] mx-auto"
            />
            <div className="px-5 mt-5">
              <h4 className="font-merriweather leading-[135%] tracking-[0.1em]">
                Melisa Garden
              </h4>
              <span className="text-[#767070] font-bold text-xs mt-1">
                Frontend Engineer
              </span>
              <p className="mt-5">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Pariatur reiciendis tenetur similique maxime! Laboriosam dolore
                deleniti eius cum deserunt porro.
              </p>
            </div>
          </div>
          <div className="member-card flex flex-col md:flex-row justify-between p-5 col-span-1 rounded">
            <img
              src={Member}
              alt=""
              className="rounded w-[170px] h-[220px] mx-auto"
            />
            <div className="px-5 mt-5">
              <h4 className="font-merriweather leading-[135%] tracking-[0.1em]">
                Melisa Garden
              </h4>
              <span className="text-[#767070] font-bold text-xs mt-1">
                Frontend Engineer
              </span>
              <p className="mt-5">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Pariatur reiciendis tenetur similique maxime! Laboriosam dolore
                deleniti eius cum deserunt porro.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Members

import Home from './pages/Home'
import Members from './pages/Members'
import { Routes, Route } from 'react-router-dom'
import './App.scss'

function App() {
  return (
    <div className="overflow-x-hidden w-screen">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/members" element={<Members />} />
      </Routes>
    </div>
  )
}

export default App

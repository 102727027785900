import React from 'react'
import Nav from '../Nav'
import Community from '../../assets/community.svg'

function About() {
  return (
    <div className="w-full bg-gradient-to-br from-secondary-yellow to-secondary-green pb-20">
      <div className="container">
        <Nav />
        <div className="flex flex-col-reverse md:flex-row mt-20 gap-5 px-5">
          <div className="md:w-1/2">
            <h1 className="font-primary text-2xl md:text-3xl leading-[135%] uppercase tracking-[0.1em]">
              <span className="text-primary-violet">Af</span>ghan{' '}
              <span className="text-primary-violet">s</span>tudent{' '}
              <span className="text-primary-violet">a</span>ssociation of{' '}
              <span className="bg-gradient-to-br bg-clip-text from-primary-blue to-primary-sky text-transparent">
                chicago
              </span>
            </h1>
            <p className="mt-6">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur
              reiciendis tenetur similique maxime! Laboriosam dolore deleniti
              eius cum deserunt porro.
            </p>
            <button className="py-3 px-14 bg-secondary-white rounded-md shadow mt-5 md:mt-11">
              Explore
            </button>
          </div>
          <div className="md:w-1/2 relative">
            <div className="bg-secondary-white rounded h-[270px] md:h-[500px] mx-auto shadow md:absolute md:right-0 md:top-0 w-full flex justify-center items-center mt-10">
              <img src={Community} alt="AFSA community" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
